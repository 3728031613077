/* your styles go here */

/* ==============================
   4. Page loader
   ============================== */

#mask {
    background-color: #000000;
    height: 100%;
    position: fixed;
    width: 100%;
    z-index: 100000;
}
.loader {
    position: absolute;
    width: 80px;
    height:80px;
    margin: -40px 0 0 -40px;
    top:50%;
    left:50%;
}
noscript{
    display: inline-block;
    text-align: center;
    color: white;
    position: absolute;
    top: 30%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}
#login-logo{
    max-width: 150px;
    width: 45%;
    min-width: 100px;
}
.btn-facebook {
    background-color: #3b5998;
    border-color: #3c5a99;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
}

.btn-twitter {
    background-color: #00ACED;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
}

.btn-linkedin {
    background-color: #007BB6;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
}

.btn-google-plus {
    background-color: #DD4B39;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
}

.btn-instagram {
    background-color: #517FA4;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
}

.btn-social{position:relative;padding-left:44px;text-align:left;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}
.btn-social :first-child{position:absolute;left:0;top:0;bottom:0;width:32px;line-height:34px;font-size:1.6em;text-align:center;border-right:1px solid rgba(0,0,0,0.2)}
.btn-social.btn-lg{padding-left:61px}
.btn-social.btn-lg :first-child{line-height:45px;width:45px;font-size:1.8em}
.btn-social.btn-sm{padding-left:38px}
.btn-social.btn-sm :first-child{line-height:28px;width:28px;font-size:1.4em}
.btn-social.btn-xs{padding-left:30px}
.btn-social.btn-xs :first-child{line-height:20px;width:20px;font-size:1.2em}
.btn-social-icon{position:relative;padding-left:44px;text-align:left;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;height:34px;width:34px;;padding-right:0}
.btn-social-icon :first-child{position:absolute;left:0;top:0;bottom:0;width:32px;line-height:34px;font-size:1.6em;text-align:center;border-right:1px solid rgba(0,0,0,0.2)}
.btn-social-icon.btn-lg{padding-left:61px}
.btn-social-icon.btn-lg :first-child{line-height:45px;width:45px;font-size:1.8em}
.btn-social-icon.btn-sm{padding-left:38px}
.btn-social-icon.btn-sm :first-child{line-height:28px;width:28px;font-size:1.4em}
.btn-social-icon.btn-xs{padding-left:30px}
.btn-social-icon.btn-xs :first-child{line-height:20px;width:20px;font-size:1.2em}
.btn-social-icon :first-child{border:none;text-align:center;width:100% !important}
.btn-social-icon.btn-lg{height:45px;width:45px;padding-left:0;padding-right:0}
.btn-social-icon.btn-sm{height:30px;width:30px;padding-left:0;padding-right:0}
.btn-social-icon.btn-xs{height:22px;width:22px;padding-left:0;padding-right:0}



.text-dark { color: #2b3d51 !important; }


/*===== Loader =====*/
.preloader { width: 30px; height: 30px; position: relative; margin: 55px auto; }

.double-bounce1, .double-bounce2 { width: 100%; height: 100%; border-radius: 50%; background-color: #205c81; opacity: 0.6; position: absolute; top: 0; left: 0; -webkit-animation: bounce 2.0s infinite ease-in-out; animation: bounce 2.0s infinite ease-in-out; -webkit-animation-delay: -1.0s; animation-delay: -1.0s; }

@-webkit-keyframes bounce { 0%,
100% { -webkit-transform: scale(0); }
    50% { -webkit-transform: scale(1); } }
@keyframes bounce { 0%,
100% { transform: scale(0); -webkit-transform: scale(0); }
    50% { transform: scale(1); -webkit-transform: scale(1); } }

/* ========== Nav =========== */

.nav-item{
    padding: 0 3px;
}
.nav-item a{
    padding: 0 6px;
}
.nav-item a i.fa{
    margin-right: 4px;
}

.page{
    min-height:100vh;
}

.page-content{
    height: 100%;
    min-height: calc( 100vh - 70px );
}
.content-inner main{
    flex: 1 0 auto;
}
.content-inner{
    position:relative;
    display: flex;
    flex-direction: column;
}
.page-header h2 i.fa{
    margin-right: 10px;
}

.shrinked .hidden-shrinked{
    display: none;
}
.relative{
    position: relative;
}
#gallery .project .project-title .text{
    width: calc( 100% - 100px );
}
#gallery .project .project-title .text p{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
#gallery .project .left-col{
    flex-wrap: wrap;
}
#gallery .project .left-col .project-date{
    padding: 4px;
}
#gallery .project .left-col .project-date select{
    display: inline-block;
    max-width: 190px;
}
#gallery .project .image .overlay{
    cursor: pointer;
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(10, 10, 10, 0.5);
}
#gallery .project .image:hover .overlay,.project .image .overlay:hover{
    display: block;
}
#gallery .project .image .overlay:before{
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "\f044";
    color: white;
}
#galleryModal .image{
    min-width: 200px;
    text-align: center;
}
#galleryModal .image img{
    max-height: 200px;
    width: auto;
    margin: 0 auto;
}
.ellipsis{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

#recipes .list-group-item{
    padding: 4px 8px;
    margin-left: 4px;
}

/*.card-close .dropdown-menu a.text-danger{*/
    /*color: #d9534f!important;*/
/*}*/
.cke_button__editmode_label{
    display: inline!important;
}
.cke_button__editmode_icon{
    display: none!important;
}
.col-inline-4{
    padding: 5px;
    width: 100%;
    float: left;
    display: inline-block;
}
@media (min-width: 992px){
    .col-inline-4{
        width: 33.3333%;
    }
}
.mr-7{
    margin-right: 70px;
}
.absolute-right{
    position: absolute;
    right: 0;
    top: 0;
}
.absolute-left{
    position: absolute;
    left: 0;
    top: 0;
}
.pointer{
    cursor: pointer;
}
.flex-1{
    flex: 1 0 auto;
}
.custom-select.is-invalid, .form-control.is-invalid, .was-validated .custom-select:invalid, .was-validated .form-control:invalid {
    border-color: #dc3545;
}
.custom-select.is-invalid:focus, .form-control.is-invalid:focus, .was-validated .custom-select:invalid:focus, .was-validated .form-control:invalid:focus {
    box-shadow: 0 0 0 0.2rem rgba(220,53,69,.25);
}
.custom-select.is-valid:focus, .form-control.is-valid:focus, .was-validated .custom-select:valid:focus, .was-validated .form-control:valid:focus {
    box-shadow: 0 0 0 0.2rem rgba(40,167,69,.25);
}
.custom-select.is-valid, .form-control.is-valid, .was-validated .custom-select:valid, .was-validated .form-control:valid {
    border-color: #28a745;
}
#ProductsTable th, #ProductsTable td {
    vertical-align: middle;
}